import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next';
import './App.css';
import VideoComponent from './components/videocomponent.jsx';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

// Contains the value and text for the options
const languages = [
  { value: '', text: "Options" },
  { value: 'en', text: "English" },
  { value: 'fr', text: "French" },
  { value: 'de', text: "German" },
  { value: 'es', text: "Spanish" },
  { value: 'zh', text: "Chinese" },
  { value: 'ko', text: "Korean" },
  { value: 'hi', text: "Hindi" },
  { value: 'it', text: "Italian" },
  { value: 'ja', text: "Japanese" }
]

function App() {

  // It is a hook imported from 'react-i18next'
  const { t } = useTranslation();

  const [lang, setLang] = useState('en');

  // This function put query that helps to
  // change the language
  const handleChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
    let loc = "http://myparadiseresorts.com/";
    window.location.replace(loc + "?lng=" + event.target.value);
  }

  return (
    <div className="App">
      <VideoComponent />
      <div className='main'>
        <div className="content">
          <h1>{t("SiteName")}</h1>
          <h2>{t('ComingSoon')}</h2>
          <Box sx={{margin:10, width: 250, backgroundColor:'white', borderRadius:2, padding:2}}>
            <InputLabel id="demo-simple-select-label" >{t('Choose')}</InputLabel>
            <Select value={lang} onChange={handleChange}>
              {languages.map(item => {
                return (<MenuItem key={item.value}
                  value={item.value}>{item.text}</MenuItem>);
              })}
            </Select>
          </Box>
        </div>
      </div>
    </div >
  );
}

export default App;
